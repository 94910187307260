var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"dashboard__container--body--col pt-3 mb-0",staticStyle:{"width":"100%"}},[_c('button',{staticClass:"btn btn__primary",on:{"click":function($event){return _vm.updateIds()}}},[_vm._v("Update Ids")]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.nonContractors,"styleClass":"vgt-table striped","search-options":{
            enabled: true,
            placeholder: 'Search this table',
          },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 10,
          }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'created')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(props.row.created))+" ")]):(props.column.field == 'link')?_c('span',[_c('router-link',{attrs:{"to":`/users/` + props.row.id,"target":"_blank"}},[_c('i',{staticClass:"fas fa-external-link ml-3 mr-3"})])],1):_vm._e()]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }