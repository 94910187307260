<template>
	<div>
  	<div class="dashboard__container--body--col pt-3 mb-0" style="width:100%;">
      <button class="btn btn__primary" @click="updateIds()">Update Ids</button>
      <vue-good-table
          :columns="columns"
          :rows="nonContractors"
          styleClass="vgt-table striped"
          :search-options="{
            enabled: true,
            placeholder: 'Search this table',
          }"
          :pagination-options="{
            enabled: true,
            mode: 'records',
            perPage: 10,
          }"
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'created'">
              {{formatDate(props.row.created)}}
            </span>
            <span v-else-if="props.column.field == 'link'">
              <router-link :to="`/users/` + props.row.id" target="_blank">
                <i class="fas fa-external-link ml-3 mr-3"></i>
              </router-link>
            </span>
          </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
const fb = require('../../firebaseConfig.js')
import * as moment from 'moment'

export default {
  name: 'nonContractors',
  components: {
    Loader,
  },
  data() {
    return {
      columns: [
        {
          label: 'created',
          field: 'created',
        },
        {
          label: 'link',
          field: 'link',
        },
        {
          label: 'First',
          field: 'firstName',
        },
        {
          label: 'Last',
          field: 'lastName',
        },
        {
          label: 'Phone',
          field: 'phone',
        },
        {
          label: 'Email',
          field: 'email',
        },

      ]
    }
  },
  computed: {
    ...mapState(['nonContractors']),
  },
  created () {
    this.$store.dispatch("getNonContractors");
  },
  methods: {
    updateIds() {
      console.log('starting')
      this.$store.dispatch("onboardContractors");
    },
    changeUsers() {
      console.log('starting')
      this.$store.dispatch("removeOldUsers");
      // fb.usersCollection.get()
      // .then((querySnapshot) => {
      //   querySnapshot.forEach((doc) => {
      //     console.log(doc.id)
      //     fb.oldUsersCollection.doc(doc.id).set(doc.data())
      //   })
      // })
      setTimeout(() => {
        // this.$store.dispatch("getUsersTotal");
      }, 2000)
    },
    removeBadUsers() {
      fb.usersCollection.get()
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MMMM Do YYYY, hh:mm a')
      } else {
        return null
      }
    },
  },
  beforeDestroy () {
    this.$store.dispatch("clearNonContractors");
  },
}
    
</script>